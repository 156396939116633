* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  color: $color-grey;
  font-family: $font-family-secondary;
  overflow-x: hidden;

  // &.body-header {
  //   padding-top: toRem(108);

  //   @include media-breakpoint-down(lg) {
  //     padding-top: toRem(118);
  //   }

  //   @include media-breakpoint-down(sm) {
  //     padding-top: toRem(112);
  //   }

  //   @include media-breakpoint-down(xs-2) {
  //     padding-top: toRem(104);
  //   }
  // }

  &.body-no-overflow {
    overflow: unset;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1 {
  font-size: toRem(56);
  font-family: $font-family-poppins;
  line-height: 1.2;
  font-weight: 500;

  @include media-breakpoint-down(sm) {
    font-size: toRem(36);
    line-height: 1.3;
  }
}

h2 {
  font-size: toRem(40);
  font-family: $font-family-poppins;
  line-height: 1.2;

  @include media-breakpoint-down(sm) {
    font-size: toRem(28);
    line-height: 1.3;
  }
}

h3 {
  font-size: toRem(24);
  font-family: $font-family-poppins;
  line-height: 1.1;

  @include media-breakpoint-down(sm) {
    font-size: toRem(20);
    line-height: 1.3;
  }
}

h4 {
  font-size: toRem(20);
  font-family: $font-family-poppins;
  line-height: 1.2;
}

h5 {
  font-size: toRem(18);
  font-family: $font-family-poppins;
  line-height: 1.2;
}

h6 {
  font-size: toRem(16);
  font-family: $font-family-poppins;
  line-height: 1.2;
}

a {
  text-decoration: none;
  color: $color-primary;
  cursor: pointer;
  transition: all linear .2s;

  &:hover {
    color: $color-text-darker;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

// For Pattern and Ornament on All Pages
.pattern,
.ornament {
  pointer-events: none;
}

// Bold
.bold {
  font-weight: 600;
}

// Italic
.italic {
  font-style: italic;
}

// Bullet List
.bullet-list {
  list-style: disc;
}

// Numered List
.numered-list {
  list-style: decimal;
}

.container {
  @include media-breakpoint-up(xl) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(xxl) {
    max-width: toRem(1212);
  }

  @include media-breakpoint-down(xl) {
    max-width: 100%;
    padding-left: toRem(24) !important;
    padding-right: toRem(24) !important;
  }

  @include media-breakpoint-down(xs-2) {
    padding-left: toRem(16) !important;
    padding-right: toRem(16) !important;
  }
}