//////////////////////////////////////////////////////////////////////////////////
//PRIMARY FONTS
@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
      url('../../media/fonts/inter/Inter-Bold.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Bold.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'),
      url('../../media/fonts/inter/Inter-Black.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Black.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Bold Italic'), local('Inter-ExtraBoldItalic'),
      url('../../media/fonts/inter/Inter-ExtraBoldItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-ExtraBoldItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'),
      url('../../media/fonts/inter/Inter-BoldItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-BoldItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Light Italic'), local('Inter-ExtraLightItalic'),
      url('../../media/fonts/inter/Inter-ExtraLightItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-ExtraLightItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'),
      url('../../media/fonts/inter/Inter-Italic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Italic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Black Italic'), local('Inter-BlackItalic'),
      url('../../media/fonts/inter/Inter-BlackItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-BlackItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Light'), local('Inter-ExtraLight'),
      url('../../media/fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-ExtraLight.woff') format('woff'),
      url('../../media/fonts/inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
      url('../../media/fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-ExtraBold.woff') format('woff'),
      url('../../media/fonts/inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'),
      url('../../media/fonts/inter/Inter-Light.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Light.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
      url('../../media/fonts/inter/Inter-Medium.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Medium.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
      url('../../media/fonts/inter/Inter-Regular.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Regular.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light Italic'), local('Inter-LightItalic'),
      url('../../media/fonts/inter/Inter-LightItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-LightItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
      url('../../media/fonts/inter/Inter-MediumItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-MediumItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
      url('../../media/fonts/inter/Inter-SemiBold.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-SemiBold.woff') format('woff'),
      url('../../media/fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Thin'), local('Inter-Thin'),
      url('../../media/fonts/inter/Inter-Thin.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-Thin.woff') format('woff'),
      url('../../media/fonts/inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold Italic'), local('Inter-SemiBoldItalic'),
      url('../../media/fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-SemiBoldItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Thin Italic'), local('Inter-ThinItalic'),
      url('../../media/fonts/inter/Inter-ThinItalic.woff2') format('woff2'),
      url('../../media/fonts/inter/Inter-ThinItalic.woff') format('woff'),
      url('../../media/fonts/inter/Inter-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
//////////////////////////////////////////////////////////////////////////////////
//SECONDARY FONTS
@font-face {
  font-family: 'Outfit';
  src: local('Outfit ExtraLight'), local('Outfit-ExtraLight'),
      url('../../media/fonts/outfit/Outfit-ExtraLight.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-ExtraLight.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Bold'), local('Outfit-Bold'),
      url('../../media/fonts/outfit/Outfit-Bold.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Bold.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit ExtraBold'), local('Outfit-ExtraBold'),
      url('../../media/fonts/outfit/Outfit-ExtraBold.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-ExtraBold.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Light'), local('Outfit-Light'),
      url('../../media/fonts/outfit/Outfit-Light.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Light.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Black'), local('Outfit-Black'),
      url('../../media/fonts/outfit/Outfit-Black.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Black.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Regular'), local('Outfit-Regular'),
      url('../../media/fonts/outfit/Outfit-Regular.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Regular.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit SemiBold'), local('Outfit-SemiBold'),
      url('../../media/fonts/outfit/Outfit-SemiBold.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-SemiBold.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Medium'), local('Outfit-Medium'),
      url('../../media/fonts/outfit/Outfit-Medium.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Medium.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit Thin'), local('Outfit-Thin'),
      url('../../media/fonts/outfit/Outfit-Thin.woff2') format('woff2'),
      url('../../media/fonts/outfit/Outfit-Thin.woff') format('woff'),
      url('../../media/fonts/outfit/Outfit-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
//////////////////////////////////////////////////////////////////////////////////
//OTHER FONTS
@font-face {
  font-family: 'Barlow';
  src: local('Barlow Black'), local('Barlow-Black'),
      url('../../media/fonts/barlow/Barlow-Black.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Black.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow ExtraBold'), local('Barlow-ExtraBold'),
      url('../../media/fonts/barlow/Barlow-ExtraBold.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-ExtraBold.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Bold'), local('Barlow-Bold'),
      url('../../media/fonts/barlow/Barlow-Bold.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Bold.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow ExtraLight'), local('Barlow-ExtraLight'),
      url('../../media/fonts/barlow/Barlow-ExtraLight.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-ExtraLight.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
      url('../../media/fonts/barlow/Barlow-SemiBold.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-SemiBold.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Light'), local('Barlow-Light'),
      url('../../media/fonts/barlow/Barlow-Light.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Light.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Thin'), local('Barlow-Thin'),
      url('../../media/fonts/barlow/Barlow-Thin.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Thin.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Medium'), local('Barlow-Medium'),
      url('../../media/fonts/barlow/Barlow-Medium.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Medium.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Regular'), local('Barlow-Regular'),
      url('../../media/fonts/barlow/Barlow-Regular.woff2') format('woff2'),
      url('../../media/fonts/barlow/Barlow-Regular.woff') format('woff'),
      url('../../media/fonts/barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Overpass';
  src: local('Overpass Black'), local('Overpass-Black'),
      url('../../media/fonts/overpass/Overpass-Black.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Black.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass ExtraBold'), local('Overpass-ExtraBold'),
      url('../../media/fonts/overpass/Overpass-ExtraBold.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-ExtraBold.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Bold'), local('Overpass-Bold'),
      url('../../media/fonts/overpass/Overpass-Bold.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Bold.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Medium'), local('Overpass-Medium'),
      url('../../media/fonts/overpass/Overpass-Medium.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Medium.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Thin'), local('Overpass-Thin'),
      url('../../media/fonts/overpass/Overpass-Thin.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Thin.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Light'), local('Overpass-Light'),
      url('../../media/fonts/overpass/Overpass-Light.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Light.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass SemiBold'), local('Overpass-SemiBold'),
      url('../../media/fonts/overpass/Overpass-SemiBold.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-SemiBold.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass ExtraLight'), local('Overpass-ExtraLight'),
      url('../../media/fonts/overpass/Overpass-ExtraLight.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-ExtraLight.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Regular'), local('Overpass-Regular'),
      url('../../media/fonts/overpass/Overpass-Regular.woff2') format('woff2'),
      url('../../media/fonts/overpass/Overpass-Regular.woff') format('woff'),
      url('../../media/fonts/overpass/Overpass-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
      url('../../media/fonts/Poppins/Poppins-SemiBold.ttf') format('ttf'),
      url('../../media/fonts/Poppins/Poppins-SemiBold.ttf') format('ttf'),
      url('../../media/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Borel';
  src: local('Borel Regular'), local('Borel-Regular'),
      url('../../media/fonts/Borel/Borel-Regular.ttf') format('ttf'),
      url('../../media/fonts/Borel/Borel-Regular.ttf') format('ttf'),
      url('../../media/fonts/Borel/Borel-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}