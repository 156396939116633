.form-check {
  position: relative;

  &--lg {
    padding-left: calc(20px + 16px);

    .form-check-input {
      width: 20px;
      height: 20px;
      margin-left: calc((20px + 16px) * -1);
    }
  }
}

.form-check-input {
  margin-top: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:checked {
    &[type="radio"] {
      background-color: $form-check-input-bg;
      background-size: 125%;
      background-position: center;
    }
  }
}


.form-check.form-switch{
  display: flex;
  flex-direction: row;
  gap: toRem(16);
  align-items: center;
  padding: 0;
  .form-check-label{
    cursor: pointer;
  }
  .form-check-input{
    cursor: pointer;
    margin-left: 0;
    position: relative;
    float: unset;
    top: unset;
    transform: unset;
    outline: none !important;
    box-shadow: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230F2F64'/%3e%3c/svg%3e");
    background-position: left center;
    background-color: $color-line-light !important;
    border-color: $color-line-light !important;
    background-size: toRem(28);
    border-color: $color-text-darker;
    &:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231E5DBC'/%3e%3c/svg%3e");
    }
  }  
}