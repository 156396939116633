.slick-track{
    display: flex !important;
}

.slick-slide{
    height: inherit !important;
}

.slick__arrows{
  height: toRem(56);
  display: flex;
  flex-direction: row;
  gap: toRem(16);
  align-items: center;
  .slick__prev,
  .slick__next{
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(56) !important;
    height: toRem(56) !important;
    border-radius: 100%;
  }
  .slick-arrow{
    display: flex !important;
    border: 1px solid transparent;
    background: transparent;
    transition: all linear .2s;
    svg{
      transition: all linear .2s;
      path{
        transition: all linear .2s;
        stroke: $color-primary;
      }
    }
    &:hover{
      border: 1px solid $color-primary;
      background: $color-primary;
      svg{
        path{
          stroke: $color-white;
        }
      }
    }
    &.slick-disabled{
      background: transparent;
      border-color: transparent;
      &:hover{
        border: 1px solid transparent;
        background: transparent;
      }
      svg{
        path{
          stroke: $color-primary;
        }
      }
    }
  }
}

.slick__dots{
  position: relative;
  .slick-dots{
    position: relative;
    top: unset;left: unset;right: unset;bottom: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: toRem(8);
    li{
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      button{
        padding: 0;
        width: toRem(53);
        height: toRem(6);
        border-radius: toRem(20);
        background: $color-text-lighter;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        font-size: 0;
        transition: all linear .2s;
        &::before,&::after{
          display: none;
        }
      }
      &.slick-active{
        button{
          background: $color-primary;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}


.slick-counter{
  color: $color-text-darker !important;
  span{
    color: $color-text-light !important;
  }
}