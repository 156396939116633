//footer section
.footer{
  position: relative;
  background: $color-tertiary;
  z-index: 1;
  margin-top: toRem(-118);
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  //container footer
  .container{
    padding: toRem(171) 0 toRem(32);
    display: flex;
    flex-direction: column;
    gap: toRem(32);
    @include media-breakpoint-down(md) {
      padding: toRem(48) toRem(24) toRem(24);
      gap: toRem(24);
    }
  }

  //footer main logo, email, phone number, and link each pages
  .footer-main{
    height: auto;
    min-height: toRem(282);
    border-bottom: 1px solid $color-text-lighter;
    padding-bottom: toRem(44);

    display: flex;
    flex-direction: row;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: toRem(32);
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: toRem(24);
    }
    .footer-box{
      display: flex;
      flex-direction: column;      
      .footer-box__images{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: toRem(5);
        margin-bottom: toRem(21);
        img{
          width: auto;
          height: auto;
          max-width: toRem(46);
          max-height: toRem(46);
        }
        span{
          font-family: $font-family-other-1;
          font-style: normal;
          font-weight: 700;
          font-size: toRem(32);
          line-height: 1.2;
          letter-spacing: 0.02em;
          color: $color-black;
        }
      }
      .footer-box__email-phone{
        display: flex;
        flex-direction: column;
        gap: toRem(6);
        color: $color-text-darker;
      }
    }
    .footer__box--links{
      display: flex;
      flex-direction: row;
      gap: toRem(90);
      padding-top: toRem(3);
      margin-left: auto;
      width: max-content;
      max-width: toRem(766);
      @include media-breakpoint-down(xxl) {
        gap: toRem(60);
      }
      @include media-breakpoint-down(lg) {
        gap: toRem(32);
        margin-left: 0;
        justify-content: space-between;
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        gap: toRem(24);
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      .links-box{
        display: flex;
        flex-direction: column;
        gap: toRem(14);
        .links-box__title{
          font-weight: 600;
          color: $color-text-darker;
          width: 100%;
        }
        .links-list--box{
          display: flex;
          flex-direction: row;
          gap: toRem(120);
          @include media-breakpoint-down(xxl) {
            gap: toRem(60);
          }
          @include media-breakpoint-down(lg) {
            gap: toRem(32);
          }
          @include media-breakpoint-down(md) {
            gap: toRem(24);
          }
          @include media-breakpoint-down(sm) {
            width: 100%;
            gap: toRem(80);
          }
          @include media-breakpoint-down(xs-2) {
            gap: toRem(40);
          }
        }
        .links-list{
          display: flex;
          flex-direction: column;
          gap: toRem(8);
          .links-item__url{
            color: $color-text-dark;
            &:hover{
              color: $color-primary;
            }
          }
        }
      }
      .links-box--template{
        margin-left: auto;
        @include media-breakpoint-down(xxl) {
          margin-left: toRem(0);
        }
      }
    }
  }

  //footer foot for copyright text
  .footer-foot{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    // @include media-breakpoint-down(lg) {
    //   display: block;
    // }
    @include media-breakpoint-down(md) {
      display: block;
    }
    .text-body{
      color: $color-text-dark !important;
    }
    .copyright-link{
      font-weight: 500;
      text-decoration: underline;
      color: $color-text-dark !important;
      &:hover{
        color: $color-primary !important;
      }
    }
  }
}
