//custom select
.custom-select {
  position: relative;
  z-index: 10;
  cursor: pointer;

  .custom-select__box {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: toRem(2);
    width: max-content;
    min-width: toRem(145);
    overflow: hidden;

    .custom-select-box__head {
      display: flex;
      gap: toRem(24);
      justify-content: space-between;
      align-items: center;
    }

    .custom-select__icon-arrow-down {
      width: auto;
      height: auto;
      max-width: toRem(20);
      max-height: toRem(20);
    }

    .custom-select__title {
      color: $color-text-light;
    }

    .cs-items__value {
      transition: all linear .2s;
      color: $color-text-darker;
      width: inherit;
      text-transform: uppercase;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .custom-select-dropdown {
    position: absolute;
    z-index: 100;
    display: none;
    background: white;
    padding: toRem(16);
    left: toRem(-16);
    top: calc(100% + toRem(-4));
    box-shadow: 0px 0px 20px 3px rgb(0 0 0 / 10%);

    &.is-open {
      display: flex;
      flex-direction: column;
      gap: toRem(2);
    }

    .custom-select__items {
      .cs-items__value {
        color: $color-text-darker;
        text-transform: uppercase;
        transition: all linear .2s;

        &:hover {
          color: $color-primary;
        }
      }

      &.active {
        .cs-items__value {
          color: $color-primary;
        }
      }
    }
  }

  //Version 2
  &.custom-select--v2 {
    background: $color-white;
    border: 1px solid $color-lighter;
    border-radius: toRem(6);

    .custom-select__box {
      padding: toRem(16) toRem(24);
      width: 100%;

      .custom-select__items.selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .custom-select-dropdown {
      align-items: flex-start;
      justify-content: flex-start;
      right: 0;
      left: 0;
      top: calc(100% + toRem(8));
      border: 1px solid $color-lighter;
      border-radius: toRem(6);
    }

    .cs-items__value {
      text-transform: capitalize !important;
      color: $color-text-dark !important;

      &:hover {
        color: $color-primary !important;
      }
    }
  }
}