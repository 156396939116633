//cta sections
.cta{
  position: relative;
  z-index: 2;
  @include media-breakpoint-down(sm) {
    top: unset;
  }
  .container{
    position: relative;
    padding: toRem(70) 0 toRem(58);
    border-radius: toRem(2);
    background: #102A83;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: toRem(40);
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: toRem(48) toRem(24);
      gap: toRem(32);  
    }
    @include media-breakpoint-down(xs-2) {
      gap: toRem(24);  
    }
  }
  
  //pattern on cta section dekstop responsive width size more than 768px
  .cta__background{
    position: absolute;
    width: max-content;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;
    @include media-breakpoint-down(sm) {      
      display: none;
    }
    &.cta__background--pattern-1{
      left: 0;right: unset;top: unset;bottom: 0;
      .cta__pattern-bg{
        display: block;
      }
    }
    &.cta__background--pattern-2{
      left: unset;right: 0;top: unset;bottom: 0;
      .cta__pattern-bg{
        display: block;
      }
    }
  }    
  //pattern on cta section mobile responsive width size less than 768px
  .cta__background--mobile{
    position: absolute;
    width: max-content;
    overflow: hidden;
    pointer-events: none;
    display: none;
    margin: 0 auto;
    z-index: 1;
    @include media-breakpoint-down(sm) {      
      display: block;
    }
    &.cta__background--pattern-1{
      left: 0;right: unset;top: 0;bottom: unset;
      .cta__pattern-bg{
        display: block;
      }
    }
    &.cta__background--pattern-2{
      left: unset;right: 0;top: unset;bottom: 0;
      .cta__pattern-bg{
        display: block;
      }
    }
  }
  .cta__title{
    color: $color-white;
    text-align: center;
    z-index: 10;
    @include media-breakpoint-down(md) {
      margin-left: toRem(36);
      margin-right: toRem(36);
    }
    @include media-breakpoint-down(xs-2) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .cta__buttons{
    z-index: 10;
    display: flex;
    flex-direction: row;
    gap: toRem(16);
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-items: stretch;
      flex-direction: column;
      width: 100%;
    }
  }

  &--v2 {
    background-color: $color-tertiary;
    .container {
      padding: 4.375rem 0;
      gap: 2rem;

      @include media-breakpoint-down(md) {
        padding: 3rem 1.5rem;
      }

      .cta__background--pattern-1,
      .cta__background--pattern-2 {
        top: 2.875rem;
      }

      .cta__background--pattern-1 {
        @include media-breakpoint-down(md) {
          top: unset;
        }
        @include media-breakpoint-down(sm) {
          top: 0;
        }
      }

      .cta__title {
        max-width: toRem(768);
        width: 100%;
      }

      .cta__buttons {
        .btn-secondary {
          background-color: $color-secondary;
          border-color: $color-secondary;
          color: $color-white;
          transition: all linear .2s;
          
          &:hover {
            background-color: $color-primary;
            border-color: $color-white;
          }
        }
      }
    }
  }

  &--v3{
    position: relative;
    z-index: 2;
    position: relative;
    padding: toRem(88) 0;  
    background: $color-primary;
    @include media-breakpoint-down(sm) {
      top: unset;
    } 
  }

  &--v4{
    position: relative;
    z-index: 2;
    position: relative;
    padding: toRem(48) 0 toRem(108);  
    background: $color-white;
    @include media-breakpoint-down(md) {
      padding: 0; 
    }
    .container{
      position: relative;
      display: flex;    
      align-items: center;
      justify-content: space-between;
      background: $color-primary;
      padding: toRem(88);
      gap: toRem(64);
      @include media-breakpoint-down(md) {
        max-width: 100%;      
        gap: toRem(32);  
        flex-direction: column;
        padding: toRem(48) 0 0;
      }
      @include media-breakpoint-down(sm) {
        gap: toRem(24);  
      }
    }

    .cta__content{
      display: flex;
      flex-direction: column;    
      align-items: flex-start;    
      gap: toRem(40);
      max-width: toRem(376);
      @include media-breakpoint-down(md) {
        max-width: 100%;      
        gap: 0;
      }
    }

    .cta__text{
      display: flex;
      flex-direction: column;    
      align-items: flex-start;    
      gap: toRem(40);
    }

    .cta__title{
      color: $color-white;    
      z-index: 10;    
      @include media-breakpoint-down(xs-2) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .cta__subtitle{
      color: $color-white;    
      z-index: 10;    
      @include media-breakpoint-down(md) {
        margin-top: toRem(8);
      }
    }

    .cta__buttons{
      z-index: 10;
      display: flex;
      flex-direction: row;
      gap: toRem(12);
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md) {
        align-items: stretch;
        flex-direction: column;
        width: 100%;
        margin-top: toRem(32);
      }
    }

    .cta__thumbnail{
      position: absolute;
      right: toRem(0);
      bottom: toRem(0);
      @include media-breakpoint-down(xl) {
        width: 100%;
        max-width: toRem(640);
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
        max-width: toRem(540);
      }
      @include media-breakpoint-down(md) {
        width: 100vw !important;
        max-width: 100vw !important;
        position: relative;
      }
    }
  }
}

.cta--v3{
  position: relative;
  z-index: 2;
  position: relative;
  padding: toRem(88) 0;  
  background: $color-primary;
  @include media-breakpoint-down(sm) {
    top: unset;
  }

  //pattern on cta section dekstop responsive width size more than 768px
  .cta__background{
    position: absolute;
    width: max-content;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: 1;
    @include media-breakpoint-down(sm) {      
      display: none;
    }
    &.cta__background--pattern-1{
      left: 0;right: unset;top: 0;bottom: unset;
      .cta__pattern-bg{
        display: block;
      }
      @include media-breakpoint-down(lg){
        display: none;
      }
    }
    &.cta__background--pattern-2{
      left: unset;right: 0;top: unset;bottom: 0;
      .cta__pattern-bg{
        display: block;
      }
      @include media-breakpoint-down(lg){
        display: none;
      }
    }
  }    

  .container{
    position: relative;
    display: flex;    
    align-items: center;
    justify-content: space-between;
    gap: toRem(64);
    @include media-breakpoint-down(lg){
      flex-direction: column;
    }
    @include media-breakpoint-down(md) {
      max-width: 100%;      
      gap: toRem(32);  
    }
    @include media-breakpoint-down(xs-2) {
      gap: toRem(24);  
    }
  }

  .cta__content{
    display: flex;
    flex-direction: column;    
    align-items: flex-start;    
    gap: toRem(40);
    max-width: toRem(376);

    @include media-breakpoint-down(lg){
      max-width: unset;
      align-items: center;
    }
  }

  .cta__text{
    display: flex;
    flex-direction: column;    
    align-items: flex-start;    
    gap: toRem(40);
  }

  .cta__title{
    color: $color-white;    
    z-index: 10;    
    @include media-breakpoint-down(xs-2) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cta__subtitle{
    color: $color-white;    
    z-index: 10;    
  }

  .cta__buttons{
    z-index: 10;
    display: flex;
    flex-direction: row;
    gap: toRem(12);
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-items: stretch;
      flex-direction: column;
      width: 100%;
    }
  }

  .cta__thumbnail{
    position: absolute;
    right: toRem(-42);
    bottom: toRem(-88);

    @include media-breakpoint-down(lg){
      position: relative;
      right: unset;
      bottom: unset;
      width: 100%;
    }
  }
}
