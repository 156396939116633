$grid-breakpoints: (
  xs: 0,
  xs-1: 320px,
  xs-2: 375px,
  xs-3: 480px,
  sm: 576px,
  md: 768px,
  md-2: 900px,
  lg: 992px,
  lg-2: 1064px,
  xl: 1200px,
  xxl: 1400px,
  xxl-2: 1440px,
);

///////////////////////////////////////
//Colors
//Brand Color
$color-primary: #1e5dbc;
$color-secondary: #4eb2f9;
$color-tertiary: #f5f9fe;

//Accents Color
$color-green: #1ebcb4;
$color-yellow: #ffb201;
$color-purple: #a27fff;
$color-red: #ff0101;

//Text Color
$color-text-darker: #0f2f64;
$color-text-dark: #536288;
$color-text-light: #8b98b8;
$color-text-lighter: #e1e3e8;

//Base Color
$color-white: #ffffff;
$color-lighter: #fbfdff;

//Other Color
$color-lighter--1: #fcfcfc;
$color-super-black: #000;
$color-black: #03133d;
$color-grey: #68718b;
$color-dark: #333333;
$color-grey--1: #b1aeaa;
$color-grey--2: #d9d9d9;
$color-neutral-10: #230604;
$color-neutral-40: #E0E0E0;
$color-neutral-70: #7C7C7C;
$color-base-70: #a0a0a0;
$color-base-80: #464646;
$color-base-90: #1a1a1a;
$color-light-grey: #cdd0d8;
$color-lighter-grey: #e6e8ec;
$color-line-light: #e8ebf1;
$color-line-light--down: #e2e2e2;
$color-other-green: #07ae60;
$color-other-yellow: #f99e26;
$color-other-light-blue : #dcf0fe;

$colorsMap: (
  primary: $color-primary,
  secondary: $color-secondary,
  tertiary: $color-tertiary,

  green: $color-green,
  yellow: $color-yellow,
  purple: $color-purple,
  red: $color-red,

  text-darker: $color-text-darker,
  text-dark: $color-text-dark,
  text-light: $color-text-light,
  text-lighter: $color-text-lighter,

  white: $color-white,
  lighter: $color-lighter,
  lighter--1: $color-lighter--1,
  super-black: $color-super-black,
  black: $color-black,
  grey: $color-grey,
  dark: $color-dark,
  grey--1: $color-grey--1,
  grey--2: $color-grey--2,
  neutral-10: $color-neutral-10,
  base-70: $color-base-70,
  base-80: $color-base-80,
  base-90: $color-base-90,
  light-grey: $color-light-grey,
  lighter-grey: $color-lighter-grey,
  line-light: $color-line-light,
  line-light--down: $color-line-light--down,
  other-green: $color-other-green,
  other-yellow: $color-other-yellow,
);

@mixin ColorClass {
  @each $name, $color in $colorsMap {
    .color-#{$name} {
      color: $color;  
    }
  }
}

@include ColorClass;

///////////////////////////////////////
// Typography
// scss-docs-start font-variables
$font-family-primary: "Inter", sans-serif;
$font-family-secondary: "Outfit", sans-serif;
$font-family-other-1: "Barlow", sans-serif;
$font-family-other-2: "Overpass", sans-serif;
$font-family-poppins: "Poppins", sans-serif;
$font-family-borel: "Borel", sans-serif;
