// Navbar
.navbar {
  position: relative;
  width: 100%;
  z-index: 999;
 
  padding: 8px 0;
  display: flex;
  align-items: center;
  background: transparent;
  transition: all linear .2s;

  &.navbar-with-bg {
    background: $color-tertiary;
  }

  // &.navbar-fixed {
  //   position: fixed;
  //   top: 0;
  // }

  @include media-breakpoint-down(xxl) {
    padding: 8px 0;
  }

  @include media-breakpoint-down(xl) {
    padding: 8px 0;
  }

  @include media-breakpoint-down(lg) {
    height: auto;
    
    background: $color-tertiary !important;
  }

  &__title {
    position: relative;
    z-index: 900;

    a {
      text-decoration: none;

      img {
        width: auto;
        height: auto;
        max-width: toRem(46);
        max-height: toRem(46);

        @include media-breakpoint-down(sm) {
          max-width: toRem(40);
          max-height: toRem(40);
        }

        @include media-breakpoint-down(xs-2) {
          max-width: toRem(32);
          max-height: toRem(32);
        }
      }
    }
  }

  .navbar__links {
    position: relative;
    display: flex;
    align-items: center;
    gap: toRem(43);
    // margin-left: 14rem;
    flex: 0 auto;

    @include media-breakpoint-down(xl) {
      // margin-left: toRem(32);
      gap: toRem(32);
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      position: absolute;
      display: none;
      width: 100%;
      height: 100vh;
      padding: 0px 20px;
      background: $color-tertiary;
      top: 0;
      left: 0;
      right: 0;
      gap: 0;
      margin-left: 0;
    }

    .navbar__links__link {
      position: relative;
      text-decoration: none;
      text-transform: capitalize;
      color: $color-text-darker;
      font-family: $font-family-secondary;
      font-size: toRem(16);
      line-height: 1.4;
      transition: 0.5s ease-out;
      display: flex;
      gap: 8px;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        position: relative;
        width: 100%;
        padding-top: toRem(20);
        padding-bottom: toRem(20);
        border-bottom: 1px solid $color-primary;
        display: flex;
        justify-content: center;

        &:first-child {
          margin-top: 90px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-top: toRem(16);
        padding-bottom: toRem(16);
      }

      &:nth-child(6) {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &:nth-child(8) {
        display: none;

        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column;
        }
      }

      &.active {
        font-family: $font-family-secondary;
        color: $color-primary;
      }

      &.active1 {
        color: $color-primary;

        .navbar__links__link__arrow {
          background-image: url('../../media/images/icons/arrow-line-down-active.svg');
          transform: rotate(180deg);
        }
      }

      &__arrow {
        width: 20px;
        height: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        background-image: url('../../media/images/icons/arrow-line-down.svg');
        transition: 0.5s ease-out;

        &.active {
          rotate: 180deg;
        }
      }

      &__btn {
        display: flex;
        gap: 8px;
        margin-left: auto;
        margin-right: auto;

        &__title {
          color: $color-text-darker;

          &.active {
            font-weight: bold;
          }
        }
      }

      .navbar__links__link__menu {
        position: relative;
        width: 100%;
        display: none;
        flex-direction: column;
        gap: toRem(32);
        max-height: toRem(140);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: toRem(8);
        }

        &::-webkit-scrollbar-track-piece {
          background-color: $color-primary;
          border-radius: toRem(8);
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-secondary;
          border-radius: toRem(8);
        }

        &.active {
          display: flex;
        }

        span.text-l-bold {
          color: $color-text-darker;

          &:hover {
            color: $color-primary;
          }
        }

        a {
          color: $color-text-darker;

          &:hover {
            color: $color-primary;
          }
        }
      }


      &:hover {
        color: $color-primary;

        .navbar__links__link__btn__title {
          color: $color-primary;
        }

        .navbar__links__link__arrow {
          background-image: url('../../media/images/icons/arrow-line-down-active.svg');
        }
      }
    }
  }

  .navbar__sign {
    position: relative;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    gap: toRem(12);
    // flex: 1;
    transition: all linear .2;

    @include media-breakpoint-down(lg) {
      position: absolute;
      justify-content: center;
      display: none;
      padding: 0px 20px;
      width: 100%;
      top: 460%;
      left: 50%;
      transform: translate(-50%, -50%);

      &>* {
        width: 100%;
      }

      &.active {
        top: 560%;
      }
    }

    @include media-breakpoint-down(xl) {
      gap: 0px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      top: 450%;
      gap: 16px;

      &.active {
        top: 570%;
      }
    }

    @include media-breakpoint-down(xs-2) {
      top: 480%;

      &.active {
        top: 620%;
      }
    }

    &__btn {
      border-radius: toRem(6);

      @include media-breakpoint-down(lg) {
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        padding: 20px 0px;
      }
    }

    .btn--book-now {
      padding-left: toRem(32);
      padding-right: toRem(32);
    }
  }

  .navbar__menu {
    position: relative;
    cursor: pointer;
    width: toRem(24);
    height: toRem(24);
    justify-content: flex-end;
    display: none;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../media/images/icons/menu.svg');
    transition: all linear .2s;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    &:hover {
      background-image: url('../../media/images/icons/menu-active.svg');
    }
  }

  .navbar__close {
    position: relative;
    cursor: pointer;
    display: none;
    justify-content: flex-end;
    width: toRem(24);
    height: toRem(24);

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../media/images/icons/close-round.svg');
    transition: all linear .2s;
    cursor: pointer;

    &:hover {
      background-image: url('../../media/images/icons/close-round-active.svg');
    }
  }
}

.js-dropdown-box {
  position: absolute;
  top: toRem(48);
  padding: 40px;
  background: $color-white;
  border-radius: 10px;
  display: none;
  gap: toRem(32);
  left: toRem(120);

  &.active1 {
    display: flex;
  }

  &__lists {
    position: relative;

    &--v1 {
      width: 368px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      @include media-breakpoint-down(xs-3) {
        width: unset;
      }

      .js-dropdown-box__lists__link {
        max-height: toRem(360);

        @include media-breakpoint-down(xs-3) {
          display: grid;
          grid-template-rows: repeat(9, 1fr);
          grid-auto-flow: column;
          max-height: none;
        }
      }
    }

    &--v2 {
      width: 151px;

      .js-dropdown-box__lists__link {
        max-height: auto;
      }
    }

    span {
      text-transform: capitalize;
      color: $color-black;
      max-width: 116px;
    }

    &__link {
      position: relative;
      // max-height: 310px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-column-gap: toRem(32);

      @include media-breakpoint-down(lg) {
        max-height: 424px;
      }

      a {
        transition: all linear .2s;
      }

      &--v1 {
        max-width: max-content;
        margin-top: 16px;
        text-decoration: none;
        color: $color-text-darker;
        font-size: 16px;
        line-height: 22.4px;

        &:hover {
          color: $color-primary;
        }
      }

      &--v2 {
        max-width: 151px;
        margin-top: 16px;
        text-decoration: none;
        color: $color-black;
        font-size: 16px;
        line-height: 22.4px;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}