
//Normal
.btn{
  display: flex;
  gap: toRem(16);
  padding: toRem(16) toRem(24);
  justify-content: center;
  align-items: center;
  border: 1px solid #102A83;
  transition: all linear .2s;
  @include media-breakpoint-down(sm) {
    padding: toRem(14) toRem(21);
  }
}

//Large
.btn-lg{
  display: flex;
  padding: toRem(18) toRem(27);
  justify-content: center;
  align-items: center;
  border: 1px solid #102A83;
  transition: all linear .2s;
  @include media-breakpoint-down(sm) {
    padding: toRem(16) toRem(24);
  }
}


//Color
.btn-primary{
  background: #102A83;
  color: $color-white;
  border-color: $color-white;
  &:hover{
    background: $color-white;
    color: #102A83;
    border-color: #102A83;
  }
}

.btn-secondary{
  background: $color-white;
  color: $color-primary;
  border-color: $color-white;
  &:hover{
    background: $color-primary;
    color: $color-white;
    border-color: $color-white;
  }

  &--border-primary{
    background: $color-white;
    color: $color-primary;
    border-color: $color-primary;
    &:hover{
      background: $color-primary;
      color: $color-white;
      border-color: $color-primary;
    }
  }
}

.btn-disabled{
background: $color-lighter;
border: 1px solid $color-text-lighter;
color: $color-text-lighter;
  &:hover{
    background: $color-lighter;
    border: 1px solid $color-text-dark;
    color: $color-text-dark;
  }
}

.btn--transparent-primary{
  background: transparent;
  border-color: transparent;
  color: $color-primary;
  &:hover{
    border-color: transparent;
    background: transparent;
    color: $color-text-darker;
  }
}
