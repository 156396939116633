/////////////////////////////////////////
//Text Body
// Extra Large
.text-body-xl {
  font-family: $font-family-secondary;
  font-size: toRem(20);
  line-height: 1.6;
  &--bold{
    font-family: $font-family-secondary;
    font-size: toRem(20);
    line-height: 1.6;
    font-weight: 700;
  }
  &--medium{
    font-family: $font-family-secondary;
    font-size: toRem(20);
    line-height: 1.6;
    font-weight: 500;
  }

  &--mobile{
    font-family: $font-family-secondary;
    line-height: 1.6;
    font-size: toRem(18);
  }

  @include media-breakpoint-down(md){
    font-size: toRem(18);
  }  
}

// Large
.text-body-lg {
  font-family: $font-family-secondary;
  font-size: toRem(18);
  line-height: 1.6;

  &--medium{
    font-family: $font-family-secondary;
    font-size: toRem(18);
    line-height: 1.6;

    font-weight: 500;
  }

  &--bold{
    font-family: $font-family-secondary;
    font-size: toRem(18);
    line-height: 1.6;

    font-weight: 600;
  }
  
  @include media-breakpoint-down(md){
    font-size: toRem(16);
  }
}

// Normal
.text-body-regular {
  font-family: $font-family-secondary;
  font-size: toRem(16);
  line-height: 1.6;
  
  &--bold{
    font-family: $font-family-secondary;
    font-size: toRem(16);
    line-height: 1.6;

    font-weight: 700;
  }
  &--medium{
    font-family: $font-family-secondary;
    font-size: toRem(16);
    line-height: 1.6;

    font-weight: 500;
  }
  
  @include media-breakpoint-down(md){
    font-size: toRem(16);
    line-height: 1.6;
  }
}

// Normal
.text-body-small {
  font-family: $font-family-secondary;
  font-size: toRem(14);
  line-height: 1.6;
  
  @include media-breakpoint-down(md){
    font-size: toRem(14);
    line-height: 1.6;
  }
}

/////////////////////////////////////////
//Text Button
// Large
.btn-lg{
  font-family: $font-family-secondary;
  font-size: toRem(20);
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  @include media-breakpoint-down(sm) {
    font-size: toRem(16);
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.02em;
  }
}

// Normal
.btn{
  font-family: $font-family-secondary;
  font-size: toRem(16);
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.02em;
  @include media-breakpoint-down(sm) {
    font-size: toRem(14);
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.02em;
  }
}



/////////////////////////////////////////
//Text Display
// Display 1
.text-display-1 {
  font-family: $font-family-primary;
  font-size: toRem(80);
  font-weight: 600;
  line-height: .96;
  
  @include media-breakpoint-down(sm) {
    font-size: toRem(56);
    font-weight: 600;
    line-height: .96;
  }  
  @include media-breakpoint-down(xs-2) {
    font-size: toRem(48);
  }
}

// Display 2
.text-display-2 {
  font-family: $font-family-primary;
  font-size: toRem(24);
  font-weight: 500;
  line-height: 1.6;
  
  @include media-breakpoint-down(sm) {
    font-size: toRem(20);
    font-weight: 500;
    line-height: 1.6;
  }
}

// Display 3
.text-display-3 {
  font-family: $font-family-primary;
  font-size: toRem(24);
  font-weight: 400;
  line-height: 1.6;
  
  @include media-breakpoint-down(sm) {
    font-size: toRem(20);
    font-weight: 400;
    line-height: 1.6;
  }
}

/////////////////////////////////////////
//Blockquote
blockquote{
  padding: toRem(48) toRem(40);
  border-left-width: toRem(4);
  border-left-color: $color-primary;
  background-color: $color-tertiary;
  font-family: $font-family-secondary;
  color: $color-primary;
  font-size: toRem(20);
  line-height: 1.6;
  @include media-breakpoint-down(md) {
    padding: toRem(32);
  }
  @include media-breakpoint-down(sm) {
    font-size: toRem(18);
    padding: toRem(24);
  }
}

.text-info{
  background-color: $color-primary;
  border-radius: toRem(4);
  color: $color-white !important;
  padding-inline: toRem(6);
  word-break: break-word;
}