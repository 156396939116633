// Datepicker
.datepicker {
  padding: toRem(16);
  font-family: $font-family-secondary;
  background: $color-white;
  border: none;
  box-shadow: 0px 0px 20px 3px rgb(0 0 0 / 10%);
  border-radius: toRem(6);
  &.datepicker-orient-bottom::before,&.datepicker-orient-bottom::after{
    display: none;
  }
  .datepicker-days,
  .datepicker-months,
  .datepicker-years,
  .datepicker-decades,
  .datepicker-centuries {
    .table-condensed {
      .datepicker-switch,
      .dow {
        font-weight: 600;
      }

      .prev,
      .datepicker-switch,
      .next,
      .old,
      .day,
      .month,
      .year,
      .decade,
      .century {
        &:hover, &:active, &:focus, &:focus-within, &:focus-visible {
          background: $color-secondary;
          color: $color-white;
        }
      }

      .today,
      .focused {
        background: $color-white;
        &:hover, &:active, &:focus, &:focus-within, &:focus-visible {
          background: $color-primary;
          color: $color-white;
        }
      }

      .active {
        background: $color-primary;
        color: $color-white;
        &:hover, &:active, &:focus, &:focus-within, &:focus-visible {
          background: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}