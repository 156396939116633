.card {
  border: none;

  &--name {
    width: fit-content;

    .card__image {
      margin-bottom: toRem(16);
      background: $color-lighter;
      display: flex;
      align-items: center;
    }

    .card__image--src {
      max-width: toRem(327);
      max-height: toRem(277);
      width: auto;
      height: auto;
      margin: auto auto 0;

      @include media-breakpoint-down(sm) {
        max-width: toRem(272);
      }
    }

    .card__desc {
      .card__desc-name {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__desc-job {
        margin-bottom: 0;
        color: $color-text-dark;
      }
    }
  }

  &--featured {
    width: fit-content;
    padding: 0 toRem(20);
    @include media-breakpoint-down(lg){
      padding: 0 toRem(16);
    }
    @include media-breakpoint-down(md){
      padding: 0 toRem(12);
    }

    .card__image {
      margin-bottom: toRem(24);
      background: $color-lighter;
      display: flex;
      align-items: center;
    }

    .card__image--src {      
      width: 100%;
      height: auto;
      margin: auto auto 0;

      @include media-breakpoint-down(sm) {
        max-width: toRem(272);
      }
    }

    .card__desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: toRem(16);
      .card__desc-name {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__desc-date {
        display: flex;
        align-items: center;
        gap: toRem(8);
      }
      .card__desc-dot{
        background: $color-text-light;
        width: toRem(6);
        height: toRem(6);
        border-radius: toRem(100);
      }
    }
  }

  &--step {
    display: flex;
    flex-direction: column;
    gap: toRem(16);
    padding: toRem(48);
    max-width: toRem(374);
    background-color: $color-white;
    border-radius: toRem(2);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding: toRem(24);
      max-width: toRem(326);
    }

    .card__icon {
      position: relative;
      max-width: toRem(56);
      max-height: toRem(56);
      width: auto;
      height: auto;
      z-index: 2;
    }

    .card__title {
      position: relative;
      margin-bottom: 0;
      color: $color-text-darker;
      z-index: 2;
    }

    .card__desc {
      position: relative;
      margin-bottom: 0;
      color: $color-text-dark !important;
      z-index: 2;
    }

    .card-step__number {
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: unset;

      font-family: $font-family-other-2;
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 700;
      font-size: toRem(254);
      line-height: 1.6;
      color: rgba(60, 80, 224, 0.03);
      z-index: 1;
    }
  }

  &--service {
    display: flex;
    flex-direction: column;
    gap: toRem(20);
    padding: toRem(32);
    width: auto;
    border-radius: toRem(2);

    &.card-service--tertiary {
      background-color: $color-tertiary;
    }

    &.card-service--white {
      background-color: $color-white;
    }

    &.card-service--tertiary-tablet {
      @include media-breakpoint-down(lg) {
        background-color: $color-tertiary !important;
      }
    }

    .card__content {
      display: flex;
      flex-direction: column;
      gap: toRem(16);

      .card__icon {
        max-width: toRem(56);
        max-height: toRem(56);
        width: auto;
        height: auto;
      }

      .card__text-group {
        .card__title {
          margin-bottom: toRem(2);
          color: $color-text-darker;
        }

        .card__desc {
          margin-bottom: 0;
          color: $color-text-dark !important;
          max-width: toRem(228);
        }
      }
    }

    .card__link {
      width: fit-content;
      background-color: $color-white;
      border-radius: 50%;
      padding: toRem(10);
      transition: all linear .2s;

      .card__link__arrow {
        width: toRem(24);
        height: toRem(24);
        vertical-align: top;
      }

      &:hover {
        background-color: $color-primary;

        .card__link__arrow {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &--service-v2{    
    width: auto;
    display: flex;
    flex-direction: column;    
    align-items: center;
    padding: toRem(32);    
    border-radius: toRem(2);
    background-color: $color-tertiary;
    transition: all .2s;

    &:hover{
      background-color: $color-white;
      box-shadow: 16px 16px 88px rgba(0, 0, 0, 0.08);
    }

    .card__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: toRem(32);

      .card__icon {
        max-width: toRem(56);
        max-height: toRem(56);
        width: auto;
        height: auto;
      }

      .card__text-group {
        .card__title {
          margin-bottom: toRem(2);
          color: $color-text-darker;
        }

        .card__desc {
          margin-bottom: 0;
          color: $color-text-dark !important;
          max-width: toRem(228);
        }
      }
    }
  }

  &--service-v3{    
    width: auto;
    display: flex;
    flex-direction: column;    
    align-items: flex-start;
    padding: toRem(24);    
    border-radius: toRem(2); 
    flex: 1;
    &:nth-child(odd){
      background-color: $color-tertiary;
    }
    &:nth-child(even){
      background-color: $color-white;
    }

    .card__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;      
      gap: toRem(16);

      .card__icon {
        max-width: toRem(32);
        max-height: toRem(32);
        width: auto;
        height: auto;
      }

      .card__text-group {
        display: flex;
        flex-direction: column;
        gap: toRem(8);
        .card__title {
          margin-bottom: toRem(2);
          color: $color-text-darker;
        }

        .card__desc {
          margin-bottom: 0;
          color: $color-text-dark !important;          
        }
      }
    }
  }

  &--location-v1 {
    position: relative;
    border-radius: toRem(2);
    width: toRem(336);

    @include media-breakpoint-down(sm) {
      width: toRem(232);
    }

    .card__back-img {
      width: 100%;
      height: auto;
      aspect-ratio: 336/461;
      object-fit: cover;
      object-position: center;
    }

    .card__content {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-white;
      padding: 1.5rem 1.5rem 0 0;
      border-top-right-radius: toRem(2);
      max-width: toRem(256);
      width: auto;

      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include media-breakpoint-down(sm) {
        padding: 1rem 1.5rem 0 0;
        max-width: 100%;
        gap: toRem(2);
        position: static;
      }

      .card__title {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__nav {
        display: flex;

        .card__address {
          margin-bottom: 0;
          color: $color-text-dark !important;
        }

        .card__link {
          max-width: toRem(24);
          max-height: toRem(24);
          width: auto;
          height: auto;

          @include media-breakpoint-down(sm) {
            display: none;
          }

          .card__link__icon {
            vertical-align: top;
          }
        }
      }
    }
  }

  &--location-v2 {
    gap: toRem(16);
    max-width: toRem(318);

    @include media-breakpoint-down(sm) {
      max-width: toRem(327);
    }

    .card__location-img {
      max-width: toRem(318);
      max-height: toRem(157);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-down(sm) {
        max-width: toRem(327);
      }
    }

    .card__location-desc {
      display: flex;
      flex-direction: column;
      gap: toRem(6);

      .card__location-desc-title {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__location-desc-address {
        margin-bottom: 0;
        color: $color-text-dark !important;
      }

      .card__location-desc-time-group {
        display: flex;
        gap: toRem(8);

        .card__location-desc-time-status {
          margin-bottom: 0;
          color: #07AE60 !important;
          font-weight: 600;
        }

        .card__location-desc-time-value {
          margin-bottom: 0;
          color: $color-text-dark !important;
        }
      }
    }

    .card__location-details {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      .card__location-link {
        display: flex;
        gap: toRem(24);
        text-decoration: none;
        transition: all linear .2s;

        &:hover {
          text-decoration: underline;
        }

        .card__location-link-text {
          margin-bottom: 0;
          color: $color-primary !important;
        }

        .card__location-link-arrow {
          max-width: toRem(24);
          max-height: toRem(24);
          width: auto;
          height: auto;
          margin-left: 0;
        }
      }
    }

    .card__location-button {
      display: none;
      border-radius: toRem(6);
      margin-top: auto;

      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
  }

  &--location-v3 {
    position: relative;
    border-radius: toRem(2);
    width: toRem(272);
    
    @include media-breakpoint-down(md-2){
      width: toRem(336);
    }

    @include media-breakpoint-down(sm) {
      width: toRem(232);
    }

    .card__back-img {
      width: 100%;
      height: auto;
      aspect-ratio: 336/461;
      object-fit: cover;
      object-position: center;
    }

    .card__content {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-white;
      padding: 1.5rem 1.5rem 0 0;
      border-top-right-radius: toRem(2);
      max-width: toRem(256);
      width: auto;

      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include media-breakpoint-down(sm) {
        padding: 1rem 1.5rem 0 0;
        max-width: 100%;
        gap: toRem(2);
        position: static;
      }

      .card__title {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__nav {
        display: flex;

        .card__address {
          margin-bottom: 0;
          color: $color-text-dark !important;
        }

        .card__link {
          max-width: toRem(24);
          max-height: toRem(24);
          width: auto;
          height: auto;

          @include media-breakpoint-down(sm) {
            display: none;
          }

          .card__link__icon {
            vertical-align: top;
          }
        }
      }
    }
  }

  &--location-v4 {
    display: flex;
    flex-direction: row;
    gap: toRem(16);
    max-width: toRem(274);

    @include media-breakpoint-down(sm) {
      max-width: unset;
      flex-direction: column;
    }

    .card__location-img {
      max-width: toRem(168);
      max-height: toRem(167);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-down(sm) {
        max-width: unset;        
      }
    }

    .card__location-content{
      display: flex;
      flex-direction: column;
      gap: toRem(16);
    }

    .card__location-desc {
      display: flex;
      flex-direction: column;
      gap: toRem(6);
      max-width: toRem(274);

      @include media-breakpoint-down(sm) {
        max-width: unset;        
      }

      .card__location-desc-title {
        margin-bottom: 0;
        color: $color-text-darker;
      }

      .card__location-desc-address {
        margin-bottom: 0;
        color: $color-text-dark !important;
      }

      .card__location-desc-time-group {
        display: flex;
        gap: toRem(8);

        .card__location-desc-time-status {
          margin-bottom: 0;
          color: #07AE60 !important;
          font-weight: 600;
        }

        .card__location-desc-time-value {
          margin-bottom: 0;
          color: $color-text-dark !important;
        }
      }
    }

    .card__location-details {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      .card__location-link {
        display: flex;
        gap: toRem(24);
        text-decoration: none;
        transition: all linear .2s;

        &:hover {
          text-decoration: underline;
        }

        .card__location-link-text {
          margin-bottom: 0;
          color: $color-primary !important;
        }

        .card__location-link-arrow {
          max-width: toRem(24);
          max-height: toRem(24);
          width: auto;
          height: auto;
          margin-left: 0;
        }
      }
    }

    .card__location-button {
      display: none;
      border-radius: toRem(6);
      margin-top: auto;

      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
  }

  &--news-v1,
  &--news-v2,
  &--news-v3 {
    flex-direction: row;
    align-items: center;
    gap: toRem(24);

    max-width: toRem(583);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: stretch;
      max-width: toRem(327);
      gap: toRem(10);
    }

    .card__news-img {
      max-width: toRem(272);
      max-height: toRem(247);
      width: 100%;
      height: auto;

      @include media-breakpoint-down(sm) {
        max-width: toRem(327);
        max-height: toRem(182);
      }
    }

    .card__news-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }

      .card__news-content__link {
        text-decoration: none;
      }

      .card__news-content__title {
        margin-bottom: 0;
        color: $color-text-darker;
        font-size: 1.5rem;
        transition: all linear .2s;

        height: auto;
        max-height: toRem(82);
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          max-height: toRem(92);
        }

        &:hover {
          color: $color-primary;
        }
      }

      .card__news-content__date {
        margin-bottom: 0;
        color: $color-text-light !important;
      }

      .card__news-content__desc {
        margin-bottom: 0;
        color: $color-text-dark !important;

        height: auto;
        max-height: toRem(144);
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }

  &--news-v3 {
    flex-direction: column;
    align-items: stretch;
    max-width: toRem(376);
    gap: 1rem;

    .card__news-img {
      max-width: toRem(376);
      max-height: toRem(247);
    }

    .card__news-content {
      padding: 0;
    }
  }
}