%full-star {
  &:after {
    content: "\f005";
  }
};

%half-star {
  &:after {
    content: "\f123";
  }
};

.rating {
  margin: 0;
  padding: 0;
  display: flex;  
  align-items: center;
  justify-content: flex-start;
  gap: toRem(8);
  color: $color-yellow;
  $this: &;

  &__item {
    font-size: toRem(22);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: FontAwesome;
    width: toRem(32);
    height: toRem(32);
    cursor: pointer;

    svg{
      fill: transparent;
      stroke: $color-text-light;
    }
    
    &:hover, &:hover ~ li{
      svg{
        fill: $color-yellow;
        stroke: $color-yellow;
      }      
    }
  }

  @for $i from 0 to 6 {
    &[data-rating='#{$i}'] {
      #{$this}__item:nth-child(-n + #{$i}) {
        @extend %full-star;
      }
    }

    &[data-rating='#{$i + 0.5}'] {
      #{$this}__item:nth-child(-n + #{$i}) {
        @extend %full-star;
      }

      #{$this}__item:nth-child(#{$i + 1}) {
        @extend %half-star;
      }
    }
  }
}
