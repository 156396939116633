.forms-group{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: toRem(2);
  &.forms-group--date{
    svg{
      position: absolute;
      bottom: toRem(19);
      right: toRem(24);
      path{
        fill: $color-text-darker;
      }
    }
  }  

  .custom-select.custom-select--v2{
    .custom-select__box{
      border: 1px solid $color-text-lighter;
      border-radius: toRem(6);
    }
  }
}
.form-control{
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid $color-text-lighter;
  border-radius: toRem(6);
  transition: all linear .2s;
  padding: toRem(16) toRem(24);
  color: $color-text-darker;
  &::placeholder{
    color: $color-text-light;
  }
  &:active,&:focus,&:focus-visible,&:focus-visible{
    border: 2px solid $color-primary;
  }
  &:disabled{
    background: transparent;
    border: 1px solid $color-text-lighter;
    color: $color-text-darker;
  }
}
.forms__checkbox{
  display: flex;
  align-items: center;
  gap: toRem(8);
  input[type='checkbox'] { 
    height: toRem(16);
    width: toRem(16);
    border:1px solid $color-grey--1;
    background: $color-lighter--1;
    cursor: pointer;
  }
}